/* Override the overlay background color */
.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.75); /* Darker background */
}

/* Customize the alert box */
/* Customize the title */
.react-confirm-alert h1 {
    color: #142357; /* Blue title */
    font-size: 1.5em;
    margin-bottom: 20px;
    font-family: Montserrat SemiBold, sans-serif;
    text-align: center; /* Center-align text */
    line-height: 1.2; /* Adjust line height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Customize the title */
.react-confirm-alert h1 {
    color: #142357; /* Blue title */
    font-size: 1.5em;
    margin-bottom: 20px;
    font-family: Montserrat SemiBold, sans-serif;
}

/* Customize the message */
.react-confirm-alert p {
    font-size: 1em;
    margin-bottom: 20px;
}

/* Customize the button group */
.react-confirm-alert-button-group {
    display: flex;
    justify-content: space-around;
}

/* Customize the buttons */
.react-confirm-alert-button-group button {
    background: #142357; /* Blue button background */
    color: #fff; /* White text */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background 0.3s ease;
}

.react-confirm-alert-button-group button:hover {
    background: #b8915b; /* Darker blue on hover */
}
